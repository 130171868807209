import {mapState,mapActions} from 'vuex';
import Vue from 'vue';
import {Toast,Dialog,Checkbox} from 'vant';

Vue.use(Checkbox);
Vue.use(Toast);
Vue.use(Dialog);
export default {
  name: 'prompt',
  components: {},
  data() {
    return {
      checked:true


    };
  },
  beforeCreate() {

  },
  created() {

  },
  methods: {
   // ...mapActions('warranty', ['myWarranty','queryorder']),
    toclaimList(){
      this.$router.push({name:'claimList'})
    }









  },
};
